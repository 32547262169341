@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    background-color: var(--ht-color-background-neutral-default);
}

body {
    margin: 0;
    color: var(--ht-color-text-default);
}

* {
    box-sizing: border-box;
    font-family: var(--ht-font-family-lato);
}

.background-alternative--result {
    background-color: var(--ht-color-background-light);
}

.text-blur {
    color: transparent;
    text-shadow: 0 0 6px rgba(0,0,0,0.5);
}

input, textarea {
    appearance: none;
}
